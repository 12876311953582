// Globals
export const HAS_COUPON = 'HAS_COUPON'
export const SITE_STATUS = 'SITE_STATUS'
export const IS_MOBILE = 'IS_MOBILE'
export const TOGGLE_PANEL = 'TOGGLE_PANEL'
export const LOAD_SOCIAL_POSTS = 'LOAD_SOCIAL_POSTS'
export const CURRENT_POST = 'CURRENT_POST'
// Globals

// Forms
export const ASET_FORM = 'ASET_FORM'
export const AFORM_ERROR = 'AFORM_ERROR'
export const AFORM_LOADING = 'AFORM_LOADING'
export const AFORM_SUBMIT = 'AFORM_SUBMIT'
export const AFORM_HAS_ERROR = 'AFORM_HAS_ERROR'
export const MSET_FORM = 'MSET_FORM'
export const MFORM_ERROR = 'MFORM_ERROR'
export const MFORM_LOADING = 'MFORM_LOADING'
export const MFORM_SUBMIT = 'MFORM_SUBMIT'
export const MFORM_HAS_ERROR = 'MFORM_HAS_ERROR'
export const M_APPLIED = 'M_APPLIED'
export const SSET_FORM = 'SSET_FORM'
export const SFORM_ERROR = 'SFORM_ERROR'
export const SFORM_RESET = 'SFORM_RESET'
export const SFORM_LOADING = 'SFORM_LOADING'
export const SFORM_SUBMIT = 'SFORM_SUBMIT'
export const SFORM_HAS_ERROR = 'SFORM_HAS_ERROR'
export const A_APPLIED = 'A_APPLIED'
export const C_FORM = 'C_FORM'
export const C_HAS_COUPON = 'C_HAS_COUPON'
export const CFORM_ERROR = 'CFORM_ERROR'
export const CFORM_LOADING = 'CFORM_LOADING'
export const CFORM_SUBMIT = 'CFORM_SUBMIT'
export const CFORM_HAS_ERROR = 'CFORM_HAS_ERROR'
export const CFORM_RESET = 'CFORM_RESET'
// Forms

// Products
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING'
export const PRODUCT_FETCH_ERROR = 'PRODUCT_FETCH_ERROR'
export const PRODUCT_ID = 'PRODUCT_ID'
// Products
