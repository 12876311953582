import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  CardSubtitle,
  StarSVGIcon,
  Text
} from 'react-md'
export default ({ content, title, rating, date }) => (
  <Card fullWidth style={{ boxShadow: 'none' }}>
    <CardHeader>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <CardSubtitle>{title}</CardSubtitle>
        <CardSubtitle>
          {new Array(rating).fill().map((_, i) => (
            <StarSVGIcon key={i} />
          ))}
        </CardSubtitle>
      </div>
    </CardHeader>
    <CardContent>
      <Text>{content}</Text>
    </CardContent>
  </Card>
)
