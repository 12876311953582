import React from 'react'
import Logo from '../../../assets/website-banner.png'
import {
  AppBar,
  AppBarTitle,
  ListItem,
  AppBarNav,
  MenuSVGIcon,
  Sheet,
  List,
  AppBarAction
} from 'react-md'
import { menuTabs } from '../../../tmp'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { TogglePanel } from '../../../store/actions/GlobalActions'
const props = (dispatch) => ({
  togglePanel: (b) => dispatch(TogglePanel(b))
})
const state = ({ globals }) => ({
  isMobile: globals.isMobile,
  panelOpen: globals.panelOpen
})
export default connect(
  state,
  props
)(({ isMobile, panelOpen, togglePanel }) => {
  const history = useHistory()

  return (
    <>
      <AppBar id="main" theme="default" fixed>
        {isMobile ? (
          <>
            <AppBarNav id="panel-control" onClick={() => togglePanel(true)}>
              <MenuSVGIcon />
            </AppBarNav>
            <AppBarTitle
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/')}
            >
              <div style={{ height: '2em' }}>
                <img src={Logo} alt="logo" style={{ height: '100%' }} />
              </div>
            </AppBarTitle>
          </>
        ) : (
          <>
            <AppBarTitle
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/')}
            >
              <div style={{ height: '2em' }}>
                <img src={Logo} alt="logo" style={{ height: '100%' }} />
              </div>
            </AppBarTitle>
            {menuTabs.map((t, i) => (
              <AppBarAction
                key={t.link}
                first={i === 0}
                buttonType="text"
                onClick={() => history.push(t.location)}
              >
                {t.link}
              </AppBarAction>
            ))}
          </>
        )}
      </AppBar>
      <Sheet
        id="menu"
        visible={panelOpen}
        onRequestClose={() => togglePanel(false)}
        position="bottom"
      >
        <List>
          {menuTabs.map((t) => (
            <ListItem
              key={t.link}
              onClick={() => {
                history.push(t.location)
                togglePanel(false)
              }}
            >
              {t.link}
            </ListItem>
          ))}
        </List>
      </Sheet>
    </>
  )
})
