import React, { useEffect } from 'react'
import {
  ArrowForwardSVGIcon,
  Card,
  CardTitle,
  Grid,
  MediaContainer,
  TextIconSpacing
} from 'react-md'
import { Button } from 'react-md'
import { connect } from 'react-redux'
import { LoadSocialPosts } from '../../../store/actions/GlobalActions'
const props = (dispatch) => ({
  getSocialPosts: () => dispatch(LoadSocialPosts())
})
const state = ({ globals }) => ({
  posts: globals.socialPosts,
  isMobile: globals.isMobile
})
export default connect(
  state,
  props
)(({ posts, getSocialPosts, isMobile }) => {
  useEffect(() => {
    getSocialPosts()
  }, [getSocialPosts])
  return (
    <Grid columns={isMobile ? 2 : 3} className="collections-grid">
      {posts && posts.length ? (
        <>
          {posts
            .filter((p) => p.media_type === 'IMAGE')
            .map((p) => (
              <Card key={p.id} style={{ padding: 0 }}>
                <MediaContainer>
                  <img src={p.media_url} alt="insta post" />
                </MediaContainer>
              </Card>
            ))}
          <Card
            style={{
              boxShadow: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CardTitle>
              <Button>
                <TextIconSpacing icon={<ArrowForwardSVGIcon />} iconAfter>
                  <a
                    href="https://www.instagram.com/average_mt/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View More
                  </a>
                </TextIconSpacing>
              </Button>
            </CardTitle>
          </Card>
        </>
      ) : null}
    </Grid>
  )
})
