import { combineReducers } from 'redux'
import globals from './Globals'
import {
  Support as support,
  Ambassador as ambassador,
  Coupon as coupons,
  Mailing as mailing
} from './Forms'
import Products from './Products'
export default combineReducers({
  globals,
  support,
  ambassador,
  coupons,
  mailing,
  products: Products
})
