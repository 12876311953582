import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
export default ({ images }) => {
  return (
    <div style={{ width: 400, margin: 'auto' }}>
      {images && images.length ? (
        <Carousel
          showArrows
          showIndicators={false}
          showStatus={false}
          //   showThumbs={false}
          infiniteLoop
          width={400}
        >
          {images
            .sort((a, b) => a.order - b.order)
            .map((i, index) => (
              <div
                key={index}
                style={{ height: '400px', width: '400px', margin: 'auto' }}
              >
                <img
                  key={index}
                  src={i.url}
                  style={{ margin: 'auto' }}
                  alt="Product"
                />
              </div>
            ))}
        </Carousel>
      ) : (
        <div style={{ width: '300px', margin: '1em', padding: 0 }}>
          <img
            src="https://breakthrough.org/wp-content/uploads/2018/10/default-placeholder-image.png"
            alt="placeholder"
          />
        </div>
      )}
    </div>
  )
}
