const iState = {
  list: [],
  currentView: null,
  productsLoading: false,
  productFetchError: false
}

export default (state = iState, { type, payload }) => {
  switch (type) {
    case 'GET_PRODUCTS':
      return { ...state, list: payload }
    case 'PRODUCTS_LOADING':
      return { ...state, productsLoading: payload }
    case 'PRODUCT_FETCH_ERROR':
      return { ...state, productFetchError: payload }
    case 'PRODUCT_ID':
      return {
        ...state,
        currentView: {
          ...payload,
          description: payload.description.split('@').filter((s) => s.length),
          images: payload.images.sort((a, b) => b.order - a.order)
        }
      }
    default:
      return state
  }
}
