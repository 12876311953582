import React from 'react'
import { Divider } from 'react-md'

export default () => (
  <section
    className="ambassador content"
    style={{ width: '80%', padding: '2em 0', margin: 'auto' }}
  >
    <h2 style={{ textAlign: 'center', margin: '2em auto 0 auto' }}>
      Why Become An Amabassador
    </h2>
    <Divider />
  </section>
)
