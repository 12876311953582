import React from 'react'
import {
  Grid,
  TextField,
  FormMessage,
  Button,
  TextArea,
  TextIconSpacing,
  SendSVGIcon,
  CircularProgress
} from 'react-md'
import { connect } from 'react-redux'
import { SetSupportForm, SubmitSupportForm } from '../../../store/actions'
import SectionHeader from './SectionHeader'

const mapDispatchToProps = (dispatch) => ({
  handleForm: (key, value) => dispatch(SetSupportForm(key, value)),
  submitForm: (formData) => dispatch(SubmitSupportForm(formData))
})

const mapStateToProps = ({ support, globals }) => ({
  supportForm: support,
  isMobile: globals.isMobile
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(({ supportForm, handleForm, submitForm, isMobile }) => {
  const handleChange = ({ target }) => {
    handleForm(target.name, target.value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    submitForm(supportForm.formData)
  }

  const displayMessage = () => {
    switch (true) {
      case supportForm.formSubmit:
        return (
          <FormMessage
            id="has-submitted"
            style={{ textAlign: 'center', margin: 'auto' }}
          >
            Thank you for reaching out, you should recieve an email shortly!
          </FormMessage>
        )
      case supportForm.FormError:
        return (
          <FormMessage
            id="has-submitted"
            style={{ textAlign: 'center', margin: 'auto' }}
          >
            {supportForm.errorMessage.split(':').map((m) => (
              <>
                {m}
                <br></br>
              </>
            ))}
          </FormMessage>
        )
      default:
        return (
          <FormMessage
            id="has-submitted"
            style={{ textAlign: 'center', margin: 'auto' }}
          ></FormMessage>
        )
    }
  }
  return (
    <Grid columns={1}>
      <SectionHeader
        title={
          <>
            Have an issue? <br></br> Contact Us For Support!
          </>
        }
      />

      <form>
        <Grid columns={1} style={{ justifyContent: 'center' }}>
          <TextField
            id="name"
            name="name"
            type="text"
            required
            label="Name"
            placeholder="Jane Doe"
            onChange={handleChange}
            value={supportForm.formData.name}
          />
          <TextField
            id="support_email"
            name="email"
            type="email"
            required
            label="Email"
            placeholder="Jane@mail.com"
            onChange={handleChange}
            value={supportForm.formData.email}
          />
          <TextArea
            id="message"
            name="message"
            type="text"
            required
            label="Message"
            placeholder="Describe you issue..."
            onChange={handleChange}
            value={supportForm.formData.message}
          />
          <Button
            type="submit"
            disabled={
              supportForm.formData.email.length &&
              supportForm.formData.name.length &&
              supportForm.formData.message.length &&
              !supportForm.formSubmit
                ? false
                : true
            }
            themeType="outline"
            theme="primary"
            onClick={handleSubmit}
            style={{
              width: isMobile ? '80%' : '50%',
              margin: 'auto'
            }}
          >
            {supportForm.formLoading ? (
              <CircularProgress id="sending" />
            ) : (
              <TextIconSpacing icon={<SendSVGIcon />} iconAfter>
                Send
              </TextIconSpacing>
            )}
          </Button>
          {displayMessage()}
        </Grid>
      </form>
    </Grid>
  )
})
