import React from 'react'
import { GridListCell, GridList } from 'react-md'
import { useHistory } from 'react-router-dom'
import ProductCard from './ProductCard'

export default ({ products, isMobile, history = useHistory() }) =>
  products ? (
    <div
      style={{
        width: '90%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <GridList maxCellSize={250}>
        {products.map((r) => (
          <GridListCell>
            <ProductCard key={r._id} product={r} push={history.push} />
          </GridListCell>
        ))}
      </GridList>
    </div>
  ) : (
    <h3>No Other Products</h3>
  )
