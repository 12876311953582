import React from 'react'
import AboutImg from '../../assets/about.jpeg'
export default () => (
  <section className="about content" style={{ paddingTop: '4em' }}>
    <div className="content-wrapper" style={{ width: '80%', margin: 'auto' }}>
      <div
        className="img-wrapper about-img"
        style={{ width: '60%', margin: 'auto' }}
      >
        <img src={AboutImg} alt="about persons" style={{ width: '100%' }} />
      </div>
      <p>
        As we began our new journey in motherhood, we also began to realize that
        the rumors were true: our lives were going to change! With the arrivals
        of our new blessings, we can’t help but spend our time mesmerized with
        the little life we have created, but also the countless hours looking
        after them. One of the hardest things about parenthood is taking away
        that time we have to cherish and enjoy with our little ones. As working
        moms, we wanted to facilitate that time and find products to help us do
        just that.
      </p>

      <p>
        Being both educators: Vanessa being a dance educator and Patty, a former
        elementary educator who has now joined her husband in the world of Real
        Estate. Optimizing our time and resources has always been a high
        priority for us. We always look for ways to to find solutions and be
        effective with our resources.
      </p>

      <p>
        How many hours do we spend researching products only to spend those same
        hours trying to return them because the product did not live up to our
        research? How many hours have been wasted or nerves tested? Wouldn’t we
        rather spend that time doing and being with those that we love? Our time
        was just too precious for that, our time was now devoted to our little
        ones, our families, and our careers. We want to give you back that time
        as well. We want to provide you with products that will not only help
        you facilitate your time, but products you can count on in your everyday
        life. Products that will give you back the time doing everything you
        love.
      </p>
    </div>
  </section>
)
