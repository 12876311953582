import React from 'react'
import { Divider, Grid, GridCell } from 'react-md'
import ReviewCard from './ReviewCard'

export default ({ reviews }) => (
  <Grid columns={1}>
    {reviews.map((review) => (
      <GridCell key={review._id} style={{ margin: 'auto' }}>
        <ReviewCard
          content={review.content}
          title={review.title}
          rating={review.rating}
          date={review.createdAt}
        />
        <Divider />
      </GridCell>
    ))}
  </Grid>
)
