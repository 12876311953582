const iState = {
  hasCoupon: false,
  currentSiteStatus: '',
  isMobile: false,
  panelOpen: false,
  socialPosts: [],
  currentPost: {}
}
export default (state = iState, { payload, type }) => {
  switch (type) {
    case 'HAS_COUPON':
      return {
        ...state,
        hasCoupon: payload
      }
    case 'LOAD_SOCIAL_POSTS':
      return { ...state, socialPosts: payload }
    case 'SELECT_POST':
      return { ...state, currentPost: payload }
    case 'SITE_STATUS':
      return { ...state, currentSiteStatus: payload }
    case 'IS_MOBILE':
      return { ...state, isMobile: payload }
    case 'TOGGLE_PANEL':
      return { ...state, panelOpen: payload }
    default:
      return state
  }
}
