import React, { useEffect } from 'react'
import {
  TextField,
  TextArea,
  Button,
  Divider,
  FormMessage,
  CircularProgress
} from 'react-md'
import AmbassdorBlurb from './components/AmbassdorBlurb'
import { connect } from 'react-redux'
import {
  CheckAFormSigned,
  HandleAForm,
  SubmitAmbassadorForm
} from '../../store/actions'

const mapDispatchToProps = (dispatch) => ({
  handleForm: (key, value) => dispatch(HandleAForm(key, value)),
  hasApplied: (boolean) => dispatch(CheckAFormSigned(boolean)),
  submitForm: (formData) => dispatch(SubmitAmbassadorForm(formData))
})

const mapStateToProps = ({ ambassador, globals }) => ({
  ambassadorForm: ambassador,
  isMobile: globals.isMobile
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(({ ambassadorForm, handleForm, hasApplied, submitForm, isMobile }) => {
  useEffect(() => {
    const signed = parseInt(localStorage.getItem('a_applied'))
    if (signed === 1) hasApplied(true)
  }, [hasApplied])

  const handleChange = (e) => {
    const { name, value } = e.target
    handleForm(name, value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    submitForm(ambassadorForm.formData)
  }
  let globalBtnStyles = {
    margin: 'auto',
    width: '50%'
  }
  let buttonOptions = {
    active: {
      onClick: handleSubmit,
      themeType: 'outline',
      theme: 'primary',
      disabled:
        ambassadorForm.formError ||
        !ambassadorForm.formData.email ||
        !ambassadorForm.formData.name ||
        !ambassadorForm.formData.application_reason
          ? true
          : false,
      style: {
        ...globalBtnStyles
      }
    },
    inactive: {
      onClick: null,
      themeType: 'flat',
      style: { textAlign: 'center', margin: 'auto', color: '#333' }
    }
  }

  let inputStyles = {
    margin: '1em auto',
    width: '100%'
  }

  let socialStyles = {
    mobile: {
      flex: 1,
      margin: '1em 0'
    },
    desktop: {
      margin: '1em 1em',
      flex: 1
    }
  }

  return (
    <div className="ambassador main" style={{ height: '100vh' }}>
      <AmbassdorBlurb />
      <section>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            margin: 'auto'
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            id="name"
            label="Name"
            name="name"
            required
            onChange={handleChange}
            value={ambassadorForm.formData.name}
            style={inputStyles}
          />
          <TextField
            id="email"
            style={inputStyles}
            required
            onChange={handleChange}
            label="Email"
            name="email"
            placeholder="jane@mail.com"
            value={ambassadorForm.formData.email}
            type="email"
          />
          <Divider />
          <div className="social-form wrapper-form">
            <h3 style={{ textAlign: 'center' }}>Social Media</h3>
            <div
              className="social-entry flex-row"
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'center'
              }}
            >
              <TextField
                id="facebook"
                required
                onChange={handleChange}
                placeholder="Jane Doe"
                style={isMobile ? socialStyles.mobile : socialStyles.desktop}
                name="facebook"
                label="Facebook Name"
                value={ambassadorForm.formData.facebook}
              />
              <TextField
                id="insta"
                style={isMobile ? socialStyles.mobile : socialStyles.desktop}
                required
                onChange={handleChange}
                placeholder="JohnAppleseed"
                name="instagram"
                label="Instagram Handle"
                value={ambassadorForm.formData.instagram}
              />
            </div>
          </div>
          <Divider />
          <TextArea
            id="reason"
            style={inputStyles}
            required
            onChange={handleChange}
            type="text"
            name="application_reason"
            placeholder="Why would you like to become an ambassador?"
            value={ambassadorForm.formData.application_reason}
          />
          {ambassadorForm.formSubmit ? (
            <FormMessage id="issigned" {...buttonOptions.inactive}>
              Your application is under review
            </FormMessage>
          ) : null}
          <Button type="submit" {...buttonOptions.active}>
            Submit Application
          </Button>
          {ambassadorForm.formSubmit ? (
            <FormMessage id="issigned" {...buttonOptions.inactive}>
              Your application is under review
            </FormMessage>
          ) : (
            <FormMessage id="notsigned"></FormMessage>
          )}
          {ambassadorForm.formLoading ? (
            <CircularProgress id="loading" style={{ marginTop: '1em' }} />
          ) : null}
          {ambassadorForm.formError ? (
            <FormMessage id="error" {...buttonOptions.inactive}>
              {ambassadorForm.errorMessage.split(':').map((m) => (
                <>
                  {m} <br></br>
                </>
              ))}
            </FormMessage>
          ) : null}
        </form>
      </section>
    </div>
  )
})
