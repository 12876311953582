import React, { useEffect } from 'react'
import { Grid, CircularProgress } from 'react-md'
import { connect } from 'react-redux'
import { FetchProducts, GetById } from '../../store/actions'
import ColorPicker from './components/ColorPicker'
import ImagePicker from './components/ImagePicker'
import TabWrapper from './components/TabWrapper'

const mapDispatchToProps = (dispatch) => ({
  getProduct: (id, d) => dispatch(GetById(id, d)),
  getRelated: (q) => dispatch(FetchProducts(q))
})

const mapStateToProps = ({ products, globals }) => ({
  products,
  isMobile: globals.isMobile
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(({ match: { params }, isMobile, getRelated, getProduct, products }) => {
  useEffect(() => {
    getProduct(params.product_id, getRelated)
  }, [params, getProduct, getRelated])

  return !products.productsLoading && products.currentView ? (
    <>
      <Grid columns={1}>
        <h2 style={{ textAlign: 'center' }}>
          {products.currentView.product_name}
        </h2>
        <Grid columns={isMobile ? 1 : 2}>
          <ImagePicker images={products.currentView.images} />
          <div
            className="info-wrapper"
            style={{
              textAlign: isMobile ? 'center' : '',
              width: isMobile ? '80%' : '50vw',
              margin: 'auto'
            }}
          >
            <h3 style={{ textAlign: 'center' }}>About This Item</h3>
            {products.currentView.description ? (
              <ul style={{ textAlign: 'left' }}>
                {products.currentView.description.map((d, index) => (
                  <li key={index} style={{ margin: '1em auto' }}>
                    {d}
                  </li>
                )) || products.currentView.description}
              </ul>
            ) : (
              'No Description Available'
            )}
          </div>
          <div style={{ height: '50%' }}></div>
        </Grid>
        <Grid columns={1}>
          <h2 style={{ textAlign: 'center' }}>Available In These Colors</h2>
          <div
            style={{
              display: 'flex',
              margin: '2em auto',
              textAlign: 'center'
            }}
          >
            {products.currentView.colors &&
              products.currentView.colors.map((c) => (
                <ColorPicker key={c._id} color={c.color} asin={c.asin} />
              ))}
          </div>
        </Grid>
      </Grid>
      <div>
        <TabWrapper
          tabs={[{ children: 'Other Products' }, { children: 'Reviews' }]}
          related={products.list.filter(
            (p) => p._id !== products.currentView._id
          )}
          reviews={products.currentView.reviews || []}
          isMobile={isMobile}
        />
      </div>
    </>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      <CircularProgress id="loader" />
    </div>
  )
})
