import {
  AmbassadorSignUp,
  SendSupport,
  SubscribeToMailingList
} from '../../services'
import {
  AFORM_ERROR,
  AFORM_HAS_ERROR,
  AFORM_LOADING,
  AFORM_SUBMIT,
  ASET_FORM,
  A_APPLIED,
  CFORM_ERROR,
  CFORM_HAS_ERROR,
  CFORM_LOADING,
  C_FORM,
  C_HAS_COUPON,
  SFORM_ERROR,
  SFORM_HAS_ERROR,
  SFORM_LOADING,
  SFORM_RESET,
  SFORM_SUBMIT,
  SSET_FORM
} from '../types'

const SetForm = (type, key, value) => ({
  type,
  payload: { key, value }
})

const FormError = (type, payload) => ({
  type,
  payload
})

const FormLoading = (type, payload) => ({
  type,
  payload
})

const FormSubmited = (type, payload) => ({
  type,
  payload
})

const FormHasError = (type, payload) => ({
  type,
  payload
})

const FormApplied = (type, payload) => ({
  type,
  payload
})

const ResetForm = (type) => ({ type })
/*====================== Ambassador Actions ======================*/
export const HandleAForm = (key, value) => {
  return (dispatch) => {
    dispatch(SetForm(ASET_FORM, key, value))
  }
}

export const CheckAFormSigned = (payload) => {
  return (dispatch) => {
    dispatch(FormApplied(A_APPLIED, payload))
  }
}

export const SubmitAmbassadorForm = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(FormLoading(AFORM_LOADING, true))
      const res = await AmbassadorSignUp(formData)
      if (res.status === 200) {
        dispatch(FormSubmited(AFORM_SUBMIT, true))
        dispatch(FormApplied(A_APPLIED, true))
        localStorage.setItem('a_applied', '1')
        dispatch(FormLoading(AFORM_LOADING, false))
      }
    } catch (error) {
      dispatch(FormLoading(AFORM_LOADING, false))
      dispatch(FormHasError(AFORM_HAS_ERROR, true))
      dispatch(
        FormError(
          AFORM_ERROR,
          'There was a problem processing your request. :  Contact us for support.'
        )
      )
    }
  }
}
/*====================== Ambassador Actions ======================*/

/*====================== Mailing Actions ======================*/
/*====================== Coupon Actions ======================*/
export const SetCouponForm = (key, value) => (dispatch) => {
  dispatch(SetForm(C_FORM, key, value))
  dispatch(FormError(CFORM_HAS_ERROR, false))
}

export const SendCoupon = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(FormLoading(CFORM_LOADING, true))
      const res = await SubscribeToMailingList(formData)
      if (res.status === 200) {
        localStorage.setItem('submitted_email', 1)
        dispatch(CheckHasCoupon(true))
        dispatch(FormLoading(CFORM_LOADING, false))
        dispatch(SetForm(C_FORM, 'email', ''))
      }
    } catch (error) {
      dispatch(FormLoading(CFORM_LOADING, false))
      dispatch(FormHasError(CFORM_HAS_ERROR, true))
      dispatch(
        FormError(
          CFORM_ERROR,
          'There was a problem sending your request. : Try again later.'
        )
      )
    }
  }
}

export const CheckHasCoupon = (payload) => ({ type: C_HAS_COUPON, payload })
/*====================== Coupon Actions ======================*/
/*====================== Mailing Actions ======================*/

/*====================== Support Actions ======================*/
export const SetSupportForm = (key, value) => {
  return (dispatch) => {
    dispatch(SetForm(SSET_FORM, key, value))
  }
}

export const SubmitSupportForm = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(FormLoading(SFORM_LOADING, true))
      const res = await SendSupport(formData)
      if (res.status === 200) {
        dispatch(FormLoading(SFORM_LOADING, false))
        dispatch(FormSubmited(SFORM_SUBMIT, true))
        dispatch(ResetForm(SFORM_RESET))
      }
    } catch (error) {
      dispatch(FormLoading(SFORM_LOADING, false))
      dispatch(FormHasError(SFORM_HAS_ERROR, true))
      dispatch(
        FormError(
          SFORM_ERROR,
          'There was a problem sending your request. : Try again later.'
        )
      )
    }
  }
}
/*====================== Support Actions ======================*/
