import React from 'react'
export default () => (
  <div style={{ maxWidth: '90%', margin: 'auto' }}>
    <img
      src="https://amt-assets.s3.amazonaws.com/tiles/website+banner.jpg"
      style={{ maxWidth: '60vw', maxHeight: '60vh', margin: 'auto' }}
      alt="Average Man Logo"
    />
  </div>
)
