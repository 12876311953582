import React, { useEffect } from 'react'
import {
  TextField,
  Grid,
  Button,
  FormMessage,
  TextIconSpacing,
  CircularProgress,
  SendSVGIcon
} from 'react-md'
import SectionHeader from './SectionHeader'
import { connect } from 'react-redux'
import {
  SendCoupon,
  SetCouponForm,
  CheckHasCoupon
} from '../../../store/actions'

const mapDispatchToProps = (dispatch) => ({
  handleForm: (key, value) => dispatch(SetCouponForm(key, value)),
  submitForm: (formData) => dispatch(SendCoupon(formData)),
  setHasCoupon: (boolean) => dispatch(CheckHasCoupon(boolean))
})

const mapStateToProps = ({ coupons, globals }) => ({
  coupon: coupons,
  isMobile: globals.isMobile
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(({ coupon, handleForm, submitForm, setHasCoupon, isMobile }) => {
  useEffect(() => {
    const previouslySubmitted = localStorage.getItem('submitted_email')
    if (parseInt(previouslySubmitted) === 1) {
      setHasCoupon(true)
    }
  }, [coupon.hasCoupon, setHasCoupon])

  const handleChange = ({ target }) => {
    handleForm(target.name, target.value)
  }

  const handleSubmit = () => {
    submitForm(coupon.email)
  }

  const whichLabel = () => {
    switch (true) {
      case coupon.hasCoupon:
        return (
          <>
            You're on our list!
            <br></br>Keep an eye on your inbox for new products and deals!
          </>
        )
      case coupon.formError:
        return (
          <>
            {coupon.errorMessage.split(':').map((m) => (
              <>
                {m}
                <br></br>
              </>
            ))}
          </>
        )
      default:
        return <></>
    }
  }

  let styles = {
    buttonMobile: {
      width: '80%',
      marginTop: '.5em'
    },
    buttonDesktop: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      width: '30%'
    },
    fieldDesktop: {
      width: '80%',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    fieldMobile: {
      width: '100%'
    },

    formDesktop: {
      display: 'flex',
      width: '80%',
      margin: 'auto'
    },
    formMobile: {
      display: 'flex',
      width: '80%',
      margin: 'auto',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }

  return (
    <Grid columns={1}>
      <SectionHeader title="Sign Up For Exclusive Deals" />
      <Grid columns={1} style={{ justifyContent: 'center' }}>
        <div style={isMobile ? styles.formMobile : styles.formDesktop}>
          <TextField
            id="email"
            disabled={coupon.hasCoupon}
            style={isMobile ? styles.fieldMobile : styles.fieldDesktop}
            type="email"
            required
            label="Your Email"
            placeholder="Jane@mail.com"
            onChange={handleChange}
            value={coupon.email}
          />
          <Button
            themeType={
              coupon.email.length && !coupon.hasCoupon ? 'outline' : 'flat'
            }
            disabled={coupon.formError || !coupon.email}
            theme="primary"
            onClick={
              coupon.email.length && !coupon.hasCoupon ? handleSubmit : null
            }
            style={isMobile ? styles.buttonMobile : styles.buttonDesktop}
          >
            <TextIconSpacing
              iconAfter
              icon={
                coupon.formLoading ? (
                  <CircularProgress id="button-loader" centered={false} />
                ) : (
                  <SendSVGIcon />
                )
              }
            >
              Sign Up
            </TextIconSpacing>
          </Button>
        </div>
        <FormMessage
          id="has-submitted"
          style={{ textAlign: 'center', margin: 'auto' }}
        >
          {whichLabel()}
        </FormMessage>
      </Grid>
    </Grid>
  )
})
