import React from 'react'
import Support from './components/Support'
import { Grid } from 'react-md'
import Banner from './components/Banner'
import NewGrid from './components/NewGrid'
import Contact from './components/Contact'
import SectionHeader from './components/SectionHeader'

export default ({ recents, isMobile }) => {
  return (
    <Grid
      columns={1}
      style={{ width: isMobile ? '100%' : '80%', margin: 'auto' }}
    >
      <Banner />
      {/* <SectionHeader title="Check Us Out On Instagram" />
      <NewGrid /> */}
      <Contact />
      <Support />
    </Grid>
  )
}
