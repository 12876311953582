import React, { useEffect } from 'react'
import { Divider, GridList } from 'react-md'
import { connect } from 'react-redux'
import { FetchProducts } from '../../store/actions'
import ProductCard from './components/ProductCard'

const mapDispatchToProps = (dispatch) => ({
  getProducts: (p, l) => dispatch(FetchProducts(p, l))
})
const mapStateToProps = ({ products, globals }) => ({
  products,
  isMobile: globals.isMobile
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(({ history: { push }, getProducts, products, isMobile }) => {
  useEffect(() => {
    getProducts()
  }, [getProducts])

  return (
    <div
      style={{
        padding: ' 4em 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <h2 style={{ textAlign: 'center' }}>Catalog</h2>
      <Divider />
      {!products.productsLoading && products.list.length ? (
        <GridList maxCellSize={250} style={{ margin: 'auto', width: '80%' }}>
          {products.list.map((p) => (
            <ProductCard key={p._id} product={p} push={push} />
          ))}
        </GridList>
      ) : (
        <h3 style={{ textAlign: 'center' }}>No Products Available Yet!</h3>
      )}
    </div>
  )
})
