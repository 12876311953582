import React, { useEffect } from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import Home from './pages/live/Home'
import Nav from './pages/live/components/Nav'
import Ambassador from './pages/live/Ambassador'
import About from './pages/live/About'
import Products from './pages/live/Products'
import ProductView from './pages/live/ProductView'

import { connect } from 'react-redux'
import { ToggleMobile } from './store/actions/GlobalActions'
import ResetPosition from './pages/live/components/ResetPosition'

const props = (dispatch) => ({
  handleResize: (b) => dispatch(ToggleMobile(b))
})

const state = ({ globals }) => ({
  isMobile: globals.isMobile
})
export default connect(
  state,
  props
)(
  withRouter(({ isMobile, handleResize }) => {
    useEffect(() => {
      window.addEventListener('resize', (ev) => {
        handleResize(ev.target.innerWidth < 800)
      })
      handleResize(window.innerWidth < 800)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [handleResize])

    return (
      <>
        <Nav isMobile={isMobile} />
        <main style={{ paddingTop: '4em' }}>
          <ResetPosition />
          <Switch>
            <Route exact path="/" render={Home} />
            <Route exact path="/products" component={Products} />
            <Route
              exact
              path="/products/details/:product_id"
              component={ProductView}
            />
            <Route path="/brand/ambassador" component={Ambassador} />
            <Route path="/about" component={About} />
          </Switch>
        </main>
      </>
    )
  })
)
