import React from 'react'
import { Button, Tooltipped } from 'react-md'

export default ({ asin, color }) => {
  const isColor = (c) => {
    let color = new Option().style
    color.color = c
    return color.color !== ''
  }
  const renderColor = (c) => {
    if (isColor(c)) {
      return c
    } else {
      switch (c) {
        case 'rose gold':
          return 'pink'
        case 'camo':
          return '#333'
        default:
          return '#333'
      }
    }
  }
  return (
    <Tooltipped tooltip="Click To View On Amazon" position="below">
      <Button style={{ textAlign: 'center' }}>
        <a
          href={`https://amazon.com/dp/${asin}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            style={{
              height: '40px',
              width: '40px',
              backgroundColor: renderColor(color.toLowerCase()),
              boxShadow: '2px 2px 10px rgba(0,0,0,0.3)',
              borderRadius: '50%',
              margin: '1em auto'
            }}
          ></div>
          {color}
          <br></br>
          View On Amazon
        </a>
      </Button>
    </Tooltipped>
  )
}
