import { GetProduct, GetProducts } from '../../services'
import {
  GET_PRODUCTS,
  PRODUCTS_LOADING,
  PRODUCT_FETCH_ERROR,
  PRODUCT_ID
} from '../types'

export const FetchProducts = (page, limit) => async (dispatch) => {
  try {
    dispatch(ToggleLoading(true))
    const payload = await GetProducts(page, limit)
    dispatch(SetProducts(payload.products))
    dispatch(ToggleLoading(false))
  } catch (error) {
    dispatch(ToggleLoading(false))
    dispatch(SetError(true))
  }
}

export const GetById = (id, done) => async (dispatch) => {
  try {
    dispatch(ToggleLoading(true))
    const payload = await GetProduct(id)
    dispatch(SetViewed(payload))
    dispatch(ToggleLoading(false))
    done()
  } catch (error) {
    dispatch(ToggleLoading(false))
    dispatch(SetError(true))
  }
}

const SetProducts = (payload) => ({
  type: GET_PRODUCTS,
  payload
})

const SetError = (payload) => ({
  type: PRODUCT_FETCH_ERROR,
  payload
})

const ToggleLoading = (payload) => ({
  type: PRODUCTS_LOADING,
  payload
})

const SetViewed = (payload) => ({
  type: PRODUCT_ID,
  payload
})
