import { GetSocialPosts } from '../../services'
import { IS_MOBILE, LOAD_SOCIAL_POSTS, TOGGLE_PANEL } from '../types'

export const ToggleMobile = (boolean) => ({
  payload: boolean,
  type: IS_MOBILE
})

export const TogglePanel = (boolean) => ({
  payload: boolean,
  type: TOGGLE_PANEL
})

export const LoadSocialPosts = () => async (dispatch) => {
  try {
    const posts = await GetSocialPosts()
    return dispatch({ type: LOAD_SOCIAL_POSTS, payload: posts })
  } catch (error) {
    throw error
  }
}
