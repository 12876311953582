import React from 'react'
import { Divider } from 'react-md'
export default ({ title }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Divider style={{ flex: 1 }} />
    <h2 style={{ flex: 2, textAlign: 'center' }}>{title}</h2>
    <Divider style={{ flex: 1 }} />
  </div>
)
