import React from 'react'
import { TabPanel, TabPanels, TabsManager, Tabs, Divider, Text } from 'react-md'
import AdditionalList from './AdditionalList'
import ReviewList from './ReviewList'

export default ({ tabs, reviews, related, isMobile }) => {
  return (
    <TabsManager stacked tabs={tabs} tabsId="tab-panel">
      <Tabs align="center" />
      <Divider />
      <TabPanels persistent={true}>
        <TabPanel>
          <AdditionalList products={related} isMobile={isMobile} />
        </TabPanel>
        <TabPanel>
          {reviews.length ? (
            <ReviewList reviews={reviews} />
          ) : (
            <Text style={{ textAlign: 'center' }}>No Reviews Available</Text>
          )}
        </TabPanel>
      </TabPanels>
    </TabsManager>
  )
}
