import axios from 'axios'

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL
      : 'http://localhost:3001/api/v1'
})

export const GetSiteStatus = async () => {
  try {
    const res = await api.get('/status')
    return res.data
  } catch (error) {
    throw error
  }
}

export const SubscribeToMailingList = async (email) => {
  try {
    const resp = await api.post('/mail', { email })
    return resp
  } catch (error) {
    throw error
  }
}

export const getProductAssets = async () => {
  try {
    const resp = await api.get('/assets/5eea16ac58d3b7cb8a1cf65b')
    return resp.data
  } catch (error) {
    throw error
  }
}

export const getRecents = async () => {
  try {
    const resp = await api.get('/products/new')
    return resp.data
  } catch (error) {
    throw error
  }
}

export const SendSupport = async (formData) => {
  try {
    const res = await api.post('/mail/support', formData)
    return res
  } catch (error) {
    throw error
  }
}

export const AmbassadorSignUp = async (formData) => {
  try {
    const res = await api.post('/ambassadors/signup', formData)
    return res
  } catch (error) {
    throw error
  }
}

export const GetProductByName = async (name) => {
  try {
    const res = await api.get(`/products/search?type=${name}`)
    return res.data
  } catch (error) {
    throw error
  }
}

export const GetProducts = async (page, limit) => {
  try {
    const res = await api.get(
      `/products?page=${page || 1}&limit=${limit || 10}`
    )
    return res.data
  } catch (error) {
    throw error
  }
}

export const GetProduct = async (id) => {
  try {
    const res = await api.get(`/products/details/view/${id}`)
    return res.data
  } catch (error) {
    throw error
  }
}

export const GetSocialPosts = async () => {
  try {
    const res = await api.get('/assets/social/instagram')
    return res.data
  } catch (error) {
    throw error
  }
}
