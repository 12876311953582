import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default () => {
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [history.location.pathname])
  return <React.Fragment />
}
