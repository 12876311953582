const cState = {
  email: '',
  hasCoupon: false,
  errorMessage: '',
  formLoading: false,
  formError: false,
  formSubmit: false
}

const aState = {
  formData: {
    name: '',
    email: '',
    instagram: '',
    facebook: '',
    application_reason: ''
  },
  errorMessage: '',
  formLoading: false,
  formError: false,
  formSubmit: false
}

const mState = {
  formData: {
    email: ''
  },
  hasApplied: false,
  errorMessage: '',
  formLoading: false,
  formError: false,
  formSubmit: false
}

const sState = {
  formData: {
    name: '',
    email: '',
    message: ''
  },
  errorMessage: '',
  formLoading: false,
  formError: false,
  formSubmit: false
}

export const Ambassador = (state = aState, { type, payload }) => {
  switch (type) {
    case 'ASET_FORM':
      return {
        ...state,
        formData: { ...state.formData, [payload.key]: payload.value }
      }
    case 'AFORM_ERROR':
      return {
        ...state,
        errorMessage: payload
      }
    case 'AFORM_LOADING':
      return {
        ...state,
        formLoading: payload
      }
    case 'AFORM_SUBMIT':
      return { ...state, formSubmit: payload }
    case 'AFORM_HAS_ERROR':
      return {
        ...state,
        formError: payload
      }
    case 'A_APPLIED':
      return {
        ...state,
        hasApplied: payload
      }
    default:
      return state
  }
}

export const Mailing = (state = mState, { type, payload }) => {
  switch (type) {
    case 'MSET_FORM':
      return {
        ...state,
        formData: { ...state.formData, [payload.key]: payload.value }
      }
    case 'MFORM_ERROR':
      return {
        ...state,
        errorMessage: payload
      }
    case 'MFORM_LOADING':
      return {
        ...state,
        formLoading: payload
      }
    case 'MFORM_SUBMIT':
      return { ...state, formSubmit: payload }
    case 'MFORM_HAS_ERROR':
      return {
        ...state,
        formError: payload
      }
    case 'M_APPLIED':
      return {
        ...state,
        hasApplied: payload
      }
    default:
      return state
  }
}

export const Support = (state = sState, { type, payload }) => {
  switch (type) {
    case 'SSET_FORM':
      return {
        ...state,
        formData: { ...state.formData, [payload.key]: payload.value }
      }
    case 'SFORM_ERROR':
      return {
        ...state,
        errorMessage: payload
      }
    case 'SFORM_LOADING':
      return {
        ...state,
        formLoading: payload
      }
    case 'SFORM_SUBMIT':
      return { ...state, formSubmit: payload }
    case 'SFORM_HAS_ERROR':
      return {
        ...state,
        formError: payload
      }
    case 'SFORM_RESET':
      return { ...state, formData: { name: '', email: '', message: '' } }
    default:
      return state
  }
}

export const Coupon = (state = cState, { type, payload }) => {
  switch (type) {
    case 'C_FORM':
      return { ...state, email: payload.value }
    case 'C_HAS_COUPON':
      return { ...state, hasCoupon: payload }
    case 'CFORM_ERROR':
      return { ...state, errorMessage: payload }
    case 'CFORM_LOADING':
      return { ...state, formLoading: payload }
    case 'CFORM_SUBMIT':
      return { ...state, formSubmit: payload }
    case 'CFORM_HAS_ERROR':
      return { ...state, formError: payload }
    case 'CFORM_RESET':
      return { ...state, email: '' }
    default:
      return state
  }
}
