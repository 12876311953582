import React from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  MediaContainer
} from 'react-md'
let imgStyles = { width: '100%', height: 250 }
export default ({ push, product }) => {
  return (
    <Button
      style={{
        padding: 0,
        width: '240px',
        margin: '.5em'
      }}
      onClick={() => push(`/products/details/${product._id}`)}
    >
      <Card style={{ width: '100%', boxShadow: 'none' }}>
        <MediaContainer>
          {product.images[0] ? (
            <img
              src={product.images.sort((a, b) => a.order - b.order)[0].url}
              alt="product"
              style={imgStyles}
            />
          ) : (
            <img
              style={imgStyles}
              src="https://breakthrough.org/wp-content/uploads/2018/10/default-placeholder-image.png"
              alt="placeholder"
            />
          )}
        </MediaContainer>
        <CardHeader>
          <CardSubtitle>{product.product_name}</CardSubtitle>
        </CardHeader>
      </Card>
    </Button>
  )
}
